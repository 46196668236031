import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerSchema from '../utils/registerSchema';
import Link from 'next/link';
import SelectInput from '../components/common/SelectInputRegister';
import TextInput from '../components/common/TextInputRegister';
import api from '../src/api';
import ErrorAlert from '../components/alerts/error.js';
import SuccessAlert from '../components/alerts/success.js';
import Loader from 'react-loader-spinner';
import { signIn } from 'next-auth/client';
import Layout from '../components/common/layout.js';
import { getSession } from 'next-auth/client';
import redirects from '../src/api/redirects.js'
import { getParams } from '../src/utils'

//show the other dropdowns

export default function Register({
  specialties,
  countries,
  states,
  workingPlaces,
}) {
  const [loading, setLoading] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const [successRegister, setSuccessRegister] = useState(false);
  const [message, setMessage] = useState('');
  const [validationMessages, setValidationMessages] = useState([]);

  const [workingPlace, setWorkingPlace] = useState(0)

  const [showWorkingPlaces, setShowWorkingPlaces] = useState(false);
  const [institutions, setInstitutions] = useState([])

  const [drugstores, setDrugstores] = useState([
    {
      id: 'Farmacia San Pablo',
      name: 'Farmacia San Pablo',
    }
  ])

  const [showOtherInstitution, setShowOtherInstitution] = useState(false);

  const [jurisdictions, setJurisdictions] = useState([]);
  const [showJurisdictions, setShowJurisdictions] = useState(false);

  const [medicalUnits, setMedicalUnits] = useState('');
  const [medicalUnitsArray, setMedicalUnitsArray] = useState([]);

  const [institution, setInstitution] = useState(0);
  const [jurisdiction, setJurisdiction] = useState(0);

  const [myCountry, setMyCountry] = useState(null)
  const [showState, setShowState] = useState(false)

  const [state, setState] = useState(0)

  const schema = registerSchema([
    {
      name: 'name',
      type: 'String',
    },
    {
      name: 'fatherLastName',
      type: 'String',
    },
    {
      name: 'motherLastName',
      type: 'String',
    },
    {
      name: 'professionalID',
      type: 'String',
    },
    {
      name: 'phone',
      type: 'Number',
    },
    {
      name: 'email',
      type: 'Email',
    },
    {
      name: 'confirmEmail',
      type: 'Email',
    },
    {
      name: 'password',
      type: 'Password',
    },
    {
      name: 'confirmPassword',
      type: 'Password',
    },
    {
      name: 'privacy',
      type: 'Boolean',
    },
    {
      name: 'specialty',
      type: 'Select',
      options: ['Selecciona una especialidad'],
    },
    {
      name: 'country',
      type: 'Select',
      options: ['Selecciona un país'],
    },
    {
      name: 'placeWork',
      type: 'Select',
      options: ['Selecciona un lugar de trabajo'],
    },
  ]);

  const { register, handleSubmit, errors, clearErrors, formState } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onChangeCountry = e => {

    if (Number(e) === 5)
      setShowState(true)

    if (Number(e) !== 5)
      setShowState(false), setShowWorkingPlaces(false), setShowOtherInstitution(false)


    if (Number(e) === 5 && (Number(workingPlace) === 2 || Number(workingPlace) === 3))
      setShowWorkingPlaces(true)

    setMyCountry(e)
  }

  const showWorkingPlacesDropdown = async e => {

    console.log('e -> ', e)

    setWorkingPlace(e)

    if (Number(myCountry) === 5 && (Number(e) === 2 || Number(e) === 3)) {
      if (!institutions.length) {
        let intitutions = await new api.GetRegisterDropdownValues('GetIntitutions').doQuery()
        setInstitutions(intitutions);
      }
      setShowWorkingPlaces(true);
    } else {
      setInstitution(0);
      setMedicalUnits('');
      setJurisdiction(0);
      //document.getElementById("other_institution").value = ""

      setShowOtherInstitution(false);
      setShowJurisdictions(false);
      setShowWorkingPlaces(false);
    }

    //if(Number(e) === 4) 

  }

  const onSelectJurisdiction = e => {
    setJurisdiction(e);
  };

  const showOtherInstitutionDropdown = e => {
    setInstitution(e);

    if (Number(e) === 6) setShowOtherInstitution(true);

    if (Number(e) !== 6) setShowOtherInstitution(false);
  };

  const onCheckedMedicalUnit = async e => {
    let checkboxValue = e.target.value;
    let checkboxChecked = e.target.checked;

    if (e.target.checked)
      setMedicalUnitsArray(oldArray => [...oldArray, checkboxValue]);

    if (!e.target.checked)
      setMedicalUnitsArray(
        medicalUnitsArray.filter(item => item !== checkboxValue)
      );

    setMedicalUnits(medicalUnitsArray.join(','));

    if (checkboxChecked && checkboxValue === 'Jurisdicción sanitaria') {
      let jurisdictions = await new api.GetRegisterDropdownValues('GetJurisdictions').doQuery()
      setJurisdictions(jurisdictions)
      setShowJurisdictions(true)
    }

    let jurisdictionsCheckbox = document.getElementById('check-unidad-m-4');
    if (!jurisdictionsCheckbox.checked) setShowJurisdictions(false);
  };

  const login = (email, accessToken, refreshToken, userID, license_verified, specialty_id, working_place_text, state_id) => {
    let params = getParams()

    let url = `${process.env.NEXTAUTH_URL}/${params?.status ? `${params.status}/${params.url}/${params.url2 ? params.url2 : ''}` : ''}`
    console.log(url)
    signIn('credentials', {
      email: email,
      access_token: accessToken,
      refresh_token: refreshToken,
      license_verified: license_verified,
      user_id: userID,
      role: 1,
      specialty_id: specialty_id,
      working_place_text: working_place_text,
      state_id: state_id,
      callbackUrl: `${url}`,
    })
  }

  const onSubmit = async data => {

    setLoading(true);
    setErrorRegister(false);

    try {
      let myMedicalUnits = '';
      let myMedicalUnitsArray = [];
      let checkboxes = document.querySelectorAll('input[type=checkbox]');
      checkboxes.forEach(row => {
        if (row.checked) myMedicalUnitsArray.push(row.value);
      });
      myMedicalUnits = myMedicalUnitsArray.join(',');

      if (myMedicalUnits) {
        myMedicalUnits = myMedicalUnits.replace('on', '');
        myMedicalUnits = myMedicalUnits.replace(/,\s*$/, '');
      }
      //console.log( 'medicalUnits->', medicalUnits )

      const {
        name,
        //veeva_id = '',
        //DCR_id = '',
        motherLastName,
        email,
        confirmEmail,
        password,
        confirmPassword,
        phone,
        specialty,
        country,
        placeWork,
        countryState,
        fatherLastName,
        professionalID,

        institution_id = institution,
        other_institution = Number(placeWork) === 1
          ? ''
          : other_institution
            ? other_institution
            : '',
        medical_units = myMedicalUnits,
        sanitary_jurisdiction_id = jurisdiction,

        role = 1,
        privacy,
        working_place_text,
      } = data

      const register = await new api.Register(name, motherLastName, email, confirmEmail, password,
        confirmPassword, phone, specialty, country, placeWork, state, fatherLastName, professionalID,
        institution_id, other_institution, medical_units, sanitary_jurisdiction_id, role, privacy, working_place_text, ).doMutation()
      

      //return

      if (!register || !register?.status)
        setErrorRegister(true), setMessage(register?.message);

      if (register.status)
        setSuccessRegister(true),
          setMessage(register?.message),

          login(
            email,
            register.access_token,
            register.refresh_token,
            register.user.id,
            register.user.license_verified,
            register.user.specialty.id,
            register.user.working_place_text,
            register.user.state.id,
          )
          
    } catch (err) {
      console.log('err->', err);
      let validationErrors = null;

      if (err.hasOwnProperty('graphQLErrors'))
        validationErrors = err.graphQLErrors[0]?.extensions?.validation;

      if (validationErrors) setValidationMessages(validationErrors);

      setMessage('Hubo un error, intente más tarde');
      setErrorRegister(true);
      setSuccessRegister(false);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => { }, [workingPlace])

  /*useEffect(() => {
    document.querySelector("body").classList.add("zendesk")

    return function unmount() {
      document.querySelector("body").classList.remove("zendesk")
    }
  }, [])*/

  return (
    <Layout>
      <div className="h-full">
        <div className="h-auto w-screen flex flex-col flex-">
          <div className="flex bg-white h-full w-full flex-wrap">
            <div
              className="w-full lg:w-1/2 flex flex-col justify-center lg:justify-start items-center h-96 lg:h-auto"
              style={{
                background: 'url(/img/sign-up-bg.jpg) center no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className="flex justify-center items-center flex-col h-1/2 lg:h-auto w-full lg:w-3/6 lg:mt-72">
                <img src="/img/bi-click-w.svg" className="w-1/3 lg:w-3/4" />
                <p className="text-white text-3xl font-semibold mt-3">
                  Videoteca Digital
                </p>
                <p className="text-white text-sm mt-1">
                  Educación médica al alcance de un click
                </p>
                <label className="text-sm px-5 py-3 rounded-full bg-white my-5 uppercase font-semibold w-auto text-center">
                  En vivo y bajo demanda
                </label>
              </div>
            </div>

            <form className="w-full lg:w-1/2 py-5 px-10" onSubmit={handleSubmit(onSubmit)}>

              <div>
                <div className="w-full flex justify-end items-center absolute top-5 right-5 lg:static">
                  <Link href="/login">
                    <div className="bg-deep-sky-blue rounded-md px-5 py-3 cursor-pointer hover:bg-bright-sky-blue transition-colors duration-300">
                      <p className="text-white text-base tracking-wide font-semibold">
                        Inicie sesión
                      </p>
                    </div>
                  </Link>
                </div>
                <p className="text-4xl font-bold mt-5 tracking-tight">
                  Nuevo Registro
                </p>
                <p className="text-sm text-gray-700 mt-3">
                  Si aún no tiene cuenta, complete el formulario de registro.
                </p>
                <p className="text-sm text-gray-700 mb-5">
                  Si ya se ha registrado, utilice su correo para iniciar sesión.
                </p>
                <div className="flex flex-col items-stretch mb-2">
                  <TextInput
                    label="Nombre(s)"
                    name="name"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="flex items-stretch my-5 flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                    <TextInput
                      label="Apellido Paterno"
                      name="fatherLastName"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="w-full lg:w-2/4">
                    <TextInput
                      label="Apellido Materno"
                      name="motherLastName"
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="flex items-stretch my-5 flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                    <SelectInput
                      name="specialty"
                      label="Especialidad"
                      options={specialties}
                      optDefault="Selecciona una especialidad"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="w-full lg:w-2/4 ">
                    <SelectInput
                      name="country"
                      label="País"
                      options={countries}
                      optDefault="Selecciona un país"
                      register={register}
                      errors={errors}
                      onChange={onChangeCountry}
                    />
                  </div>
                </div>

                {
                  showState &&
                  <div className="flex items-stretch my-5 flex-wrap lg:flex-nowrap">
                    <div className="w-full">
                      <SelectInput
                        name="countryState"
                        label="Estado"
                        options={states}
                        optDefault="Selecciona un estado"
                        register={register}
                        errors={errors}
                        onChange={setState}
                      />
                    </div>
                  </div>
                }


                <div className="flex items-stretch my-5 flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                    <TextInput
                      label="Cédula Profesional"
                      name="professionalID"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="w-full lg:w-2/4 ">
                    <TextInput
                      label="Teléfono Móvil"
                      name="phone"
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-stretch my-5">
                  <TextInput
                    label="Correo electrónico"
                    name="email"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="flex flex-col items-stretch my-5">
                  <TextInput
                    label="Confirme su correo electrónico"
                    name="confirmEmail"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="flex items-stretch mt-5 mb-3 flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                    <TextInput
                      password
                      label="Cree una contraseña"
                      name="password"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="w-full lg:w-2/4 ">
                    <TextInput
                      password
                      label="Confirme su contraseña"
                      name="confirmPassword"
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>
                <p className="text-sm text-gray-400 mb-5">
                  Ingrese una contraseña con al menos 5 caracteres (letras y/o
                  números). La contraseña distingue letras mayúsculas y
                  minúsculas.
                </p>
                <div className="flex flex-col items-stretch mb-3">
                  <SelectInput
                    name="placeWork"
                    label="Lugar de Trabajo"
                    options={workingPlaces}
                    optDefault="Selecciona un lugar de trabajo"
                    register={register}
                    errors={errors}
                    onChange={showWorkingPlacesDropdown}
                  />
                </div>

                {
                  workingPlace === '4' &&
                  <div className="w-full mb-10">
                    <SelectInput
                          name="working_place_text"
                          label="Seleccione la farmacia a la que pertenece"
                          options={drugstores}
                          optDefault="Seleccione un opción"
                          register={register}
                          errors={errors}
                          //onChange={showOtherInstitutionDropdown}
                        />
                  </div>
                }

                {
                  showWorkingPlaces && (
                    <>
                      <div className="flex flex-col items-stretch mb-3">
                        <SelectInput
                          name="institution"
                          label="Institución a la que pertenece"
                          options={institutions}
                          optDefault="Selecciona la intitución a la que pertenece"
                          register={register}
                          errors={errors}
                          onChange={showOtherInstitutionDropdown}
                        />
                      </div>

                      {
                        showOtherInstitution && (
                          <div className="flex flex-col items-stretch mb-3">
                            <TextInput
                              label="Otro"
                              name="other_institution"
                              register={register}
                              errors={errors}
                            />
                          </div>
                        )}

                      <div className="flex flex-col items-stretch mb-3">
                        <label className="required px-3 mt-8 block w-full text-tiny uppercase relative">
                          Unidad Médica
                      </label>
                        <div className="px-3 mt-2 mb-4">
                          <input
                            id="check-unidad-m-1"
                            type="checkbox"
                            value="Hospital"
                            onChange={onCheckedMedicalUnit}
                          />
                          <span>Hospital</span>
                          <br />
                          <input
                            id="check-unidad-m-2"
                            type="checkbox"
                            value="Centro de Salud"
                            onChange={onCheckedMedicalUnit}
                          />
                          <span>Centro de Salud</span>
                          <br />
                          <input
                            id="check-unidad-m-3"
                            type="checkbox"
                            value="Clínica de medicina familiar"
                            onChange={onCheckedMedicalUnit}
                          />
                          <span>Clínica de medicina familiar</span>

                          <br />
                          <input
                            id="check-unidad-m-4"
                            type="checkbox"
                            value="Jurisdicción sanitaria"
                            onChange={onCheckedMedicalUnit}
                          />
                          <span>Jurisdicción sanitaria</span>
                          <br />
                          <input
                            id="check-unidad-m-5"
                            type="checkbox"
                            value="Otra"
                            onChange={onCheckedMedicalUnit}
                          />
                          <span>Otra</span>
                        </div>
                      </div>

                      {showJurisdictions && (
                        <div className="flex flex-col items-stretch mb-3">
                          <SelectInput
                            name="jurisdictions"
                            label="Jurisdicción Sanitaria"
                            options={jurisdictions}
                            optDefault="Selecciona la jurisdicción sanitaria a la que pertenece"
                            register={register}
                            errors={errors}
                            onChange={onSelectJurisdiction}
                          />
                        </div>
                      )}
                    </>
                  )}

                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <input
                      id="privacy"
                      name="privacy"
                      type="checkbox"
                      className="h-4 w-4 text-deep-sky-blue focus:ring-deep-sky-blue border-gray-300 rounded"
                      ref={register}
                    />
                    <label htmlFor="privacy" className="ml-2 block text-sm text-gray-700 leading-relaxed">
                      Conforme a la Ley Federal de Protección de Datos
                      Personales en Posesión de los Particulares, manifiesto
                      conocer el contenido y alcance del{' '}
                      <span className="text-deep-sky-blue cursor-pointer">Aviso de privacidad
                      </span>{' '}
                      de Boehringer-Ingelheim México, S.A. DE C.V., otorgando
                      con esta firma mi consentimiento informado para que mis
                      datos personales sean tratados como en él se establece.
                    </label>
                  </div>
                </div>
                <p className="text-xs text-red-400 pl-5">
                  {errors.privacy?.message &&
                    'Aceptar el Aviso de Privacidad es requerido'}
                </p>
                <div className="w-full flex justify-center my-5">
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    className="flex justify-center py-3 px-20 lg:px-10 uppercase border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-deep-sky-blue hover:bg-bright-sky-blue transition ease-in duration-100 focus:outline-none tracking-widest"
                  >
                    Registrarse
                    {loading && (
                      <Loader
                        className="ml-2 mt-1"
                        type="ThreeDots"
                        color="#FFFFFF"
                        height={20}
                        width={20}
                      />
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-4 flex align-middle justify-center">
                {errorRegister && (
                  <ErrorAlert
                    message={
                      Object.keys(validationMessages).length
                        ? validationMessages
                        : message
                    }
                  />
                )}
                {successRegister && <SuccessAlert message={message} />}
              </div>

            </form>

          </div>
        </div>
      </div>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const session = await getSession(context);

  if (session) return redirects.guest();

  let specialties = await new api.GetRegisterDropdownValues(
    'getSpecialties'
  ).doQuery();
  let countries = await new api.GetRegisterDropdownValues(
    'getCountries'
  ).doQuery();
  let states = await new api.GetRegisterDropdownValues('GetStates').doQuery();

  let workingPlaces = await new api.GetRegisterDropdownValues('GetWorkingPlaces').doQuery();

  return {
    props: {
      specialties,
      countries,
      states,
      workingPlaces,
    },
  };
}
