import { error } from 'pdf-lib';

const TextInputRegister = ({
  label,
  name,
  register,
  errors,
  password = null,
}) => {


  const handleError = mensaje => {
    switch (name) {
      case 'name':
        return 'El Nombre es requerido';
        break;
      case 'fatherLastName':
        return 'El Apellido Paterno es requerido';
        break;
      case 'motherLastName':
        return 'El Apellido Materno es requerido';
        break;
      case 'professionalID':
        return 'La Cédula es requerida';
        break;
      case 'phone':
        return 'El Teléfono es requerido';
        break;
      case 'email':
        return 'El Email es requerido';
        break;
      case 'confirmEmail':
        return 'La Confirmación del Email debe ser igual al Email';
        break;
      case 'password':
        return 'La Contraseña es requerida';
        break;
      case 'confirmPassword':
        return 'La Confirmación de la Contraseña debe ser igual a la Contraseña';
        break;
    }
  };

  return (
    <>
      <label htmlFor={name} className="text-sm text-gray-600 mb-2 block">
        {label}
      </label>

      <input
        type={`${password ? 'password' : 'text'}`}
        name={name}
        id={name}
        className="w-full py-2 px-4 shadow-sm focus:ring-deep-sky-blue focus:border-deep-sky-blue border-gray-300 rounded-lg"
        ref={register}
      />
      {errors[name]?.message && (
        <p className="text-xs text-red-400 pl-5 mt-1">
          {handleError(errors[name]?.message)}
        </p>
      )}
    </>
  );
};

export default TextInputRegister;
